import React from "react";
import Alert from "@material-ui/lab/Alert";
import {
  Grid,
  Typography,
  //   Divider,
  //   Paper,
  //   Typography,
  Button,
  //   Avatar,
  //   TextField,
  //   List,
  //   ListItem,
  //   ListItemIcon,
  //   ListItemText,
  //   ListItemAvatar,
  LinearProgress,
  Divider,
} from "@material-ui/core";
// import {
//   Store,
//   LocationOn,
//   FitnessCenter,
//   Speed,
//   PictureAsPdf,
//   Description,
// } from "@material-ui/icons";
// import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../utils/utility";
// import { mdiConsoleLine } from "@mdi/js";
import Toast from "../components/Toast";
// import { settings } from "../utils/config";
import DataTable from "../components/DataTable";

function ViewHistory() {
  //   let { id } = useParams();
  //   const classes = myStyles();
  const [progress, setProgress] = React.useState("hidden");
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     padding: 10,
  //     paddingTop: 60,
  //   },
  // }));
  // const classes = useStyles();
  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  const [tableData, setTableData] = React.useState([{ id: 0 }]);

  React.useEffect(() => {
    loadData();
  }, []);

  // React.useEffect(() => {
  //   setProgress("hidden");
  //   }, [tableData]);

  //   function convertNumber(num, locale) {
  //     const { format } = new Intl.NumberFormat(locale);
  //     const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
  //     return +num
  //       .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
  //       .replace(decimalSign, ".");
  //   }

  async function loadData() {
    try {
      setProgress("visible");
      const mydata = await dataProvider("get", "/u/shipments-delivered/50", []);
      // console.log(mydata.data.data[0],"td");
      setTableData(mydata.data.data);
      setProgress("hidden");
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
      setProgress("hidden");
    }
  }

  const columns = [
    { field: "id", headerName: "Order#", width: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 160,
      editable: false,
    },
    {
      field: "shipper",
      headerName: "Shipper",
      width: 210,
      editable: false,
    },
    {
      field: "tracking",
      headerName: "Tracking#",
      width: 210,
      editable: false,
    },
    {
      field: "total_charge",
      headerName: "Total",
      // type: 'number',
      width: 120,
      // editable: true,
    },
    {
      field: "delivered_date",
      headerName: "Delivered On",
      // type: 'number',
      width: 190,
      // editable: true,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={11}>
          <Typography variant="h6" gutterBottom>
            Shipment History
          </Typography>
          <Divider />
          {tableData[0]?.tracking ? (
            <Grid item xs={12}>
              {!tableData[0]?.tracking ? (
                <Alert severity="info">No delivered packages 😒</Alert>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <div>
              <LinearProgress style={{ visibility: progress }} />
              {/* {console.log(tableData[0]?.tracking)} */}
              {tableData[0]?.tracking === undefined && progress === "hidden" ? (
                <>
                  <Alert severity="info">No delivered packages. 😒</Alert>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      color="default"
                      //   type="submit"
                      onClick={() => window.history.back()}
                      // type="submit"
                    >
                      Go Back
                    </Button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={11}>
          {/* <Paper> */}
          {tableData[0]?.tracking ? (
            <DataTable data={tableData} columns={columns} />
          ) : (
            ""
          )}
          {/* </Paper> */}
        </Grid>
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
export default ViewHistory;
