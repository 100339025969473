import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/Nav";
import Dashboard from "./pages/Dashboard";
// import Account from "./pages/Account";
// import Packages from "./pages/Packages";
import PrivateRoute from "./components/PrivateRoute";
// import Login from "./pages/Login";
import { settings } from "./utils/config";
import { signOut } from "./utils/utility";
import ViewPackage from "./pages/ViewPackage";
import ViewHistory from "./pages/ViewHistory";
import NotFound from "./pages/NotFound";
import RequestDelivery from "./pages/RequestDelivery";
import PreAlert from "./pages/PreAlert";
import ViewPreAlerts from "./pages/ViewPreAlerts";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          {/* <Route path="/account" exact>
            <Nav comp={<Account />} />
          </Route> */}

          {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
          <PrivateRoute
            component={Nav}
            subcomponent={Dashboard}
            path="/"
            exact
          />

          <PrivateRoute
            component={Nav}
            subcomponent={Dashboard}
            path="/dashboard"
            exact
          />
          <PrivateRoute
            component={Nav}
            subcomponent={ViewPackage}
            path="/package/:id"
            exact
          />
          <PrivateRoute
            component={Nav}
            subcomponent={ViewHistory}
            path="/history"
            exact
          />
          <PrivateRoute
            component={Nav}
            subcomponent={RequestDelivery}
            path="/request-delivery"
            exact
          />
          <PrivateRoute
            component={Nav}
            subcomponent={PreAlert}
            path="/pre-alert"
            exact
          />
          <PrivateRoute
            component={Nav}
            subcomponent={ViewPreAlerts}
            path="/pre-alerts"
            exact
          />

          {/* <PrivateRoute path="/" exact>
            <Nav comp={<Dashboard />} />
          </PrivateRoute> 
          <PrivateRoute path="/dashboard" exact>
            <Nav comp={<Dashboard />} />
          </PrivateRoute> */}

          {/* <Route path="/packages" exact>
            <Nav comp={<Packages />} />
          </Route>
          */}
          {/* <Route path="/login" exact>
            <Login />
          </Route> */}

          <Route
            path="/signin"
            component={() => {
              window.location.href = settings.signin_url;
              return null;
            }}
            exact
          />
          <Route
            path="/signout"
            component={() => {
              signOut(settings.website, settings.hostname);
              return null;
            }}
            exact
          />
          <Route
            path="/signup"
            component={() => {
              window.location.href = settings.signup_url;
              return null;
            }}
            exact
          />
          <Route
            path="/crm"
            component={() => {
              window.location.href = settings.crm_url;
              return null;
            }}
            exact
          />
          <Route
            path="/settings"
            component={() => {
              // console.log(document.referrer, "ref");
              window.location.href = settings.settings_url;
              return null;
            }}
            exact
          />
{/* 
          <Route path="*">
            <NotFound />
          </Route> */}
          <Route component={NotFound} />

          {/* <Route path="/" exact>
            <Nav comp={<Dashboard />} />
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
