import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {settings} from "../utils/config";

export default function DataGridDemo(props) {
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    // console.log(props, "props");
    // setTableTitle(props.option?.title);
    setTableData(props.data);
  }, [props]);

  return (
    <div style={{ height: 400, width: '100%', background:'white' }}>
      {tableData?<DataGrid
        // rows={rows}
        rows={tableData}
        columns={props?.columns}
        pageSize={6}
        onRowClick ={
          (r,e)=>{
            window.open(settings.invoice+r.id)
          }
        }
        // checkboxSelection
        // disableSelectionOnClick
      />:''}
    </div>
  );
}
