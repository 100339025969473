import React from "react";
import { myStyles } from "../utils/config";
import {
  Grid,
  Divider,
  Paper,
  Typography,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import {
  DoneOutline,
  MonetizationOn,
  FlightTakeoff,
  CenterFocusStrong,
} from "@material-ui/icons";
// import ImageIcon from "@material-ui/icons/Image";
// import MessageBoard from "../components/MessageBoard";
import Package from "../components/Package";
// import PackageItem from "../components/PackageItem";
import Mailbox from "../components/Mailbox";
import {dataProvider} from "../utils/utility"
// import { mdiConsoleLine } from "@mdi/js";
import Toast from "../components/Toast";
import logo_g from "../assets/sc_grey.png";
function Dashboard() {
  const classes = myStyles();
   //Toast ----------------------------------------
   const [isToastOpen, setIsToastOpen] = React.useState(false);
   const [toastMessage, setToastMessage] = React.useState("");
   const handleToastClose = () => {
     setIsToastOpen(false);
   };
   // -----------------------------------------------------
  const [data, setData] = React.useState([]);
  const [haspack, setHasPack] = React.useState(false);
  const [user_info, setUserInfo] = React.useState({});
  const [dash, setDash] = React.useState({
    ready: 0,
    in_transit: 0,
    balance: 0
  });
  let counter = 0;
  React.useEffect(()=>{
    loadData();
    loadInfo();

  },[]) 
 
  function convertNumber(num, locale) {
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
      .replace(decimalSign, '.');
  }

  async function loadInfo() {
    try {
      const mydata = await dataProvider('get', '/u/info', []);
       setUserInfo(mydata.data.data); 

       if(mydata.data.data.trn === "" || mydata.data.data.trn === null || mydata.data.data.trn === "123456789" || mydata.data.data.trn === "000000000"){
        alert("Please update your TRN to continue");
        window.location = "settings";
       }
      //  console.log(mydata.data.data,"user-info"); 
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  async function loadData() {
    let bal = 0;
    let ready_cnt = 0;
    let in_transit_cnt = 0;
    try {
      const mydata = await dataProvider('get', '/u/shipments/30', []);
      // console.log(mydata.status,"status")
       setData(mydata.data.data);
      //  console.log(mydata.data.data);
      if(mydata.data.data.length < 1){
        setHasPack(true);
      }
       for (let value of Object.values(mydata.data.data)) {
        // console.log(value)
        if(value.package_state === 'ready'){
          bal += convertNumber(value.total_charge, 'en-US');
          ready_cnt += 1;
        }
          if(value.package_state === 'in-transit'){
          in_transit_cnt += 1;
        } 
      }
      // console.log(bal, "B");
      setDash({balance:bal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    ready: ready_cnt, in_transit:in_transit_cnt})
    } catch (error) {
      // console.log(error.status, "err")
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  return (
    <div>
      <Mailbox data={user_info} />
      <Grid container spacing={1} style={{marginTop:-10}}>
        <Grid item xs={6} lg={4} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#08AEEA",
                    backgroundImage:
                      "linear-gradient(0deg, #08AEEA 0%, #2AF598 100%)",
                  }}
                >
                  <DoneOutline />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>{dash.ready}</span>}
                secondary="Ready"
              />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={4} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#FBAB7E",
                    backgroundImage:
                      "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)",
                  }}
                >
                  <FlightTakeoff />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>{dash.in_transit}</span>}
                secondary="In-Transit"
              />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={4} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#FFE53B",
                    backgroundImage:
                      "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)",
                  }}
                >
                  <MonetizationOn />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>{dash.balance}</span>}
                secondary="Balance"
              />
            </ListItem>
          </Paper>
        </Grid>

        {/* <Grid item xs={6} lg={3} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#21D4FD",
                    backgroundImage:
                      "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
                  }}
                >
                  <Money />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>340</span>}
                secondary="Points"
              />
            </ListItem>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <MessageBoard />
        </Grid>  */}
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <Typography component="span" variant="h5" className={classes.title}>
            My Packages
          </Typography>
          <div className={classes.section3} style={{ float: "right" }}>
            {/* <Button color="primary">See all</Button> */}
          </div>
        </Grid>
        <Grid container>
          <Divider />
        
           {data?.map((value) => {            
            
            return <Package data={value} key={counter++} />
           })} 

         
        </Grid>

        {(haspack === true && data?.length === 0)?<Grid item xs={12} style={{textAlign:"center", paddingTop:50}} >
            <img  style={{opacity:'0.26', width:'40%', maxWidth:200}} src={logo_g}/>
            <Typography variant="subtitle1" style={{color:'#999', marginTop:18, marginBottom:30}}>
            You have no packages at the moment.
          </Typography>
          </Grid>:''}
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
export default Dashboard;
