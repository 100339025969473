import React from "react"
import jwt_decode from "jwt-decode";
import { scroller } from "react-scroll";
import {
  DoneOutline,
  FlightTakeoff,
  Inbox,
 
} from "@material-ui/icons";
var axios = require("axios");
var Cookies = require("js-cookie");

export const config = {
  domain: "",
  domain_auth: "",
}

if(process.env.NODE_ENV === 'development'){
  config.domain = "http://localhost:5652";
  config.domain_auth = "http://localhost:5651";

  config.domain = "https://api.shippcartja.com/";
  config.domain_auth = "https://auth.shippcartja.com/";

    
} else {
  config.domain = "https://api.shippcartja.com/";
  config.domain_auth = "https://auth.shippcartja.com/";
}


axios.defaults.timeout = 59000;
export const axios_app = axios.create({
  baseURL: config.domain,
  timeout: 59000,
  
  // headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

const axios_auth = axios.create({
  baseURL: config.domain_auth,
  timeout: 59000,
  // headers: {'X-Custom-Header': 'foobar'}
});

export const scrolly = (ele) => {
  scroller.scrollTo(ele, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -90,
  });
};

export function goTo(u){
  window.location.href = u;
}
function getToken(){
   const c =  Cookies.get('cb_token', { path: '/', domain: '.shippcartja.com' });

  if(process.env.NODE_ENV === 'development'){
    return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InBhdHJpY2subWFsY29sbUBnbWFpbC5jb20iLCJhY2NvdW50X2lkIjoiMTQ3IiwiYWNjb3VudF90eXBlIjoiYWRtaW4iLCJwZXJtaXNzaW9uIjoyLCJpYXQiOjE3MTk2MjU1NzgsImV4cCI6MTcxOTYzNjM3OH0.JivU73Fo34PFvXLl4TPp_2GynSU0QzKlN7CNNjQlCFA"
  } else {
    return c;
  }
}
const css ={
  colorReceived: {
    color: "grey",
    // backgroundColor: "grey"
    borderColor: "grey",
    // color:"white"
  },
  colorInTransit: {
    color: "orange",
    borderColor: "orange",
    // backgroundColor: "orange"
    // color:"white"
  },
  colorReady: {
    color: "#0CB6E1",
    // color:"white"
  },
  colorDelivered: {
    color: "green",
    // color:"white"
  },
  colorOnHold: {
    color: "#ff2f4c",
    // color:"white"
  },
}

export const packIcon = (value) => {
  switch (value) {
    case "received":
      return {
        css: css.colorReceived,
        progress: 33,
        icon: <Inbox className={css.colorReceived} />,
        color: "#efefef",
        // icon: (
        //   <SvgIcon>
        //     <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
        //   </SvgIcon>
        // ),
      };
    case "in-transit":
      return {
        progress: 66,
        css: css.colorInTransit,
        icon: <FlightTakeoff className={css.colorInTransit} />,
        color: "#F8C26F",
      };
    case "ready":
      return {
        progress: 100,
        css: css.colorReady,
        icon: <DoneOutline className={css.colorReady} />,          
        color: "#0EBADD",
      };
    case "delivered":
      return {
        progress: 100,
        css: css.colorDelivered,
        icon: <DoneOutline className={css.colorDelivered} />,
        color: "#18CFC4",
      };
    case "onhold":
      return {
        progress: 100,
        css: css.colorOnHold,
        icon: <Inbox className={css.colorOnHold} />,
        color: "#f45368",
      };
    default:
      return { css: css.colorReceived, icon: "" };
  }
};

export async function dataProvider(method, url, data) {
    // getToken();
    // return false;
    try {
      const response = await axios_app({
        method: method,
        url: url,
        data:  data ,
        headers: { Authorization: "Bearer " + getToken() },
      });
      // console.log(response.status, "provider")
      // console.log(response.response, "provider")
        return response;
    } catch (error) {
        // console.log(error.headers.status, "est")
        // console.log(error.response, "est")

        if (error.response) {
            // console.log(error.response.data);
            if(error.response.status === 403){
                window.location = "signin";
                return false;
            }
         
          }
          if(error.response){
             throw new Error(error.response?.data.message) 
          } else {
            throw new Error("Request timeout.")
          } 
      
    }
  }
export async function ajax(method, url, data) {
    // getToken();
    // return false;
    try {
      const response = await axios_app({
        method: method,
        url: url,
        data:  data ,
        headers: { Authorization: "Bearer " + getToken() },
      });
      // console.log(response.status, "provider")
      // console.log(response.response, "provider")
        return response;
    } catch (error) {
        // console.log(error.headers.status, "est")
        // console.log(error.response, "est")

        if (error.response) {
            // console.log(error.response.data);
            if(error.response.status === 403){
                // window.location = "https://account.shippcartja.com/signin";
                return false;
            }
         
          }
      throw new Error(error);
      
    }
  }

  export function checkAuth() {
    // var token = localStorage.getItem("token");
   var token =  getToken();
    if (!token) {
      return false;
    }
  
    // token = JSON.parse(token);

    try {
        var jwt = jwt_decode(token);
    } catch (error) {
        return false;
    }
    
    let d = parseInt(Date.now() / 1000);
    if (d < jwt.exp) {
        // console.log('check', true);
      return true;
    } else {
    //   localStorage.removeItem("token");
      Cookies.remove('cb_token', { path: '/', domain: '.shippcartja.com' });
      // console.log('check', false);
      return false;
    }
  }

  export async function doLogin(data) {
    try {
      const response = await axios_auth.post("login", data);
      // console.log(response);
      return response;
    } catch (error) {
      // return error.message;
      throw new Error(error);
    }
  }

  export function signOut(u, hostname){
    Cookies.remove('cb_token', { path: '/', domain: '.'+hostname});
    window.location.href = u;
  }