import React from "react";
import {
  Grid,
  Typography,
  Collapse,
  Button,
  Divider,
} from "@material-ui/core";
import {
  FileCopyOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons/";
//   import { myStyles } from "../utils/config";
import { makeStyles } from "@material-ui/core/styles";
import {settings} from "../utils/config";
import Toast from "./Toast";

function Mailbox(props) {
  const thisStyle = makeStyles(() => ({
    mycard: {
      borderRadius: 9,

      //   minHeight: 200,
      padding: 15,
      marginTop: 6,
      marginBottom: 0,
      color: "#f4f4f4",
      backgroundColor: "#21D4FD",
      //   backgroundImage: "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
      backgroundImage:
        "linear-gradient( 135.9deg,  rgba(109,25,252,1) 16.4%, rgba(125,31,165,1) 56.1% );",
    },
  }));

   //Toast ----------------------------------------
   const [isToastOpen, setIsToastOpen] = React.useState(false);
   const [toastMessage, setToastMessage] = React.useState("");
   const handleToastClose = () => {
     setIsToastOpen(false);
   };
   // -----------------------------------------------------

  // const reveal = 50;
  // const classes = myStyles();
  const css = thisStyle();
  const [checked, setChecked] = React.useState('');
  const handleChange = () => {
    setChecked((prev) => !prev);
    localStorage.setItem('showBox', !checked);
  };

  function copyElementText(text) {
    // var text = document.getElementById(id).innerText;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    setToastMessage(text+" Copied.");
    setIsToastOpen(true);
  }

  React.useEffect(()=>{
let collapse = localStorage.getItem('showBox');
    if(collapse){
      if(collapse === true || collapse === 'true'){
        setChecked(true);
      } else {
        setChecked(false);
        localStorage.setItem('showBox', false);
      }
    } else {
      localStorage.setItem('showBox', true);
      setChecked(true);
    }
  },[])
  return (
    <div>
        {/* <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ fontWeight: 600, textAlign: "center", color:"#424242" }}
                onClick={handleChange}
              >
               {props.data.fname} {props.data.lname}
              </Typography> 
             
            </Grid>
          </Grid> */}
      <Collapse
        in={checked}
        // collapsedHeight={reveal}
        // style={{ paddingBottom: 10, }}
      >
          

        <div className={css.mycard}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Address Line 1</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                3750 W Oakland Park Blvd.
                 {/* <Button  onClick={()=> {copyElementText('3750 W Oakland Blvd.')}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}></Button> */}
              </Typography>
              
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Address Line 2</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                {(props?.data?.account_id)?(settings.prefix+" "+props?.data?.account_id):settings.prefix}
                 {/* <Button  onClick={()=> {copyElementText(settings.prefix+" "+props.data.account_id)}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}></Button> */}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">City</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                Lauderdale Lakes
                  {/* <Button  onClick={()=> {copyElementText("Lauderdale Lakes")}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}></Button> */}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">State</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                Florida
                  {/* <Button  onClick={()=> {copyElementText("Florida")}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}></Button> */}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Zip</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
              33311
                {/* <Button  onClick={()=> {copyElementText("33311")}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}></Button> */}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", color: "white" }}>
              <Divider
                style={{ background: "#8541a3" }}
              />
              <Button  onClick={()=> {copyElementText("3750 W Oakland Park Blvd.\n"+settings.prefix+" "+props.data.account_id+"\nLauderdale Lakes\nFlorida\n33311")}} style={{ color: "white", opacity:'0.45' }} startIcon={<FileCopyOutlined />}> Copy Address</Button>
            
            </Grid>
          </Grid>
        </div>
      </Collapse>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          paddingBottom: 6,
          marginBottom: 5,
          paddingTop:0,
          marginTop:0
        }}
      >
        <Button onClick={handleChange} style={{height:'21px'}}>
          {/* {checked ? <div style={{marginTop:15}}><KeyboardArrowUp/></div> : <span style={{fontSize:'0.7em'}}> <KeyboardArrowDown style={{margin:0,padding:0}}/></span>} */}
          {checked ? <div><KeyboardArrowUp/></div> : <span style={{fontSize:'0.6em',paddingTop:3}}><div>Show Mailing Address</div> <KeyboardArrowDown style={{margin:0,padding:0}}/></span>}
        </Button>
      </div>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}

export default Mailbox;
