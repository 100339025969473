import React, { useState } from "react";
import { myStyles } from "../utils/config";
import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { scroller } from "react-scroll";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  LinearProgress,
  Divider,
  TextareaAutosize,
} from "@material-ui/core";

import { dataProvider } from "../utils/utility";
// import { mdiConsoleLine } from "@mdi/js";
import Toast from "../components/Toast";
// import { settings } from "../utils/config";
import DataTable from "../components/DataTable";
import { CalendarToday, LocalShipping } from "@material-ui/icons";
import moment from "moment";
import { setDate } from "date-fns";
// var moment = require('moment-timezone');
// moment().tz("America/Jamaica").format();

function PreAlert() {
  //   let { id } = useParams();
  let history = useHistory();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const classes = myStyles();
  const [progress, setProgress] = React.useState("hidden");

  // console.log(tomorrow)
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     padding: 10,
  //     paddingTop: 60,
  //   },
  // }));
  // const classes = useStyles();
  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  const [tableData, setTableData] = React.useState([{ id: 0 }]);
  const [fdata, setFormData] = React.useState({merchant: ""});
  const handleChange = (event) => {
    // if(event.target.checked === false) return;
    setFormData({ ...fdata, [event.target.name]: event.target.value });
  };
 
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -120,
    });
  };

  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      input.value = "";
    });
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    ); 
  setFormData({merchant:""});
    setSelectedDate(null);
  };


  const handleFormSubmit = () => {
   
 
    if (!selectedDate) {
      setToastMessage("Invalid date");
      setIsToastOpen(true);
      // scrolly("invoiceName");
      return false;
    }

    if (!fdata.contents) {
      setToastMessage("Please provide description of contents");
      setIsToastOpen(true);
      scrolly("contents");
      return false;
    }
    if (!fdata.tracking) {
      setToastMessage("Please tracking #");
      setIsToastOpen(true);
      scrolly("tracking");
      return false;
    }
    if (!fdata.merchant) {
      setToastMessage("Please enter merchant");
      setIsToastOpen(true);
      scrolly("merchant");
      return false;
    }

    let edate = moment(selectedDate).isValid()
		? moment(selectedDate).format('YYYY-MM-DD')
		: ''

    dataProvider("post", "/u/preAlert", {
      fdata,
      edate,
    })
      .then((res) => {
        // console.log(res);
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setFormData({});
        // setSelectedDate("");
        if(res.data.error === false){
          reset();
        }
      })
      .catch((err) => {
        console.log(err);
        setToastMessage("Something went wrong, please try again.");
        setIsToastOpen(true);
      });

    // const fdata = new FormData();
    // fdata.append("invoiceName", formData.invoiceName);
  };

  function disableWeekends(date) {
    return date.getDay() === 0;
  }
  async function loadData() {
    try {
      setProgress("visible");
      const mydata = await dataProvider("get", "/u/shipments-ready/20", []);
      // console.log(mydata.data.data,"td");
      setTableData(mydata.data.data);
      // setTableData({});
      setProgress("hidden");
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
      setProgress("hidden");
    }
  }

  // const merchants = [
  //   "Amazon Logistics",
  //   "DHL",
  //   "UPS",
  //   "FedEX",
  //   "USPS",
  //   "Other"
  // ];

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={11}>
          <Typography variant="h6" gutterBottom>
            Make Pre-Alert
          </Typography>
          <Divider />
          <form className={classes.formRoot} autoComplete="off">
            <input type="hidden" name="deliveries_ele" />

            <Typography variant="h6" gutterBottom>
              <LocalShipping />
              &nbsp; Shipment details
            </Typography>
            {/* <Box sx={{ maxWidth: 320 }}>
              <FormControl fullWidth>
               
                <Select
                  required
                  displayEmpty
                  variant="outlined"
                  id="merchant"
                  name="merchant"
                  label="Merchant"
                  value={fdata.merchant}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                >
                 <MenuItem disabled value=""><em>Select courier</em></MenuItem>            
                  {merchants.map(merchant => (
                    <MenuItem key={merchant} value={merchant}>
                      {merchant}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
            <TextField
              label="Merchant"
              variant="outlined"
              className={classes.input}
              name="merchant"
              onChange={handleChange}
              required
              // multiline
              // rows={2}
              // rowsMax={2}
              style={{ marginTop: 30 }}
              //   disabled
              id="merchant"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },

                // maxLength: 86,
              }}
          

          />
            <TextField
              label="Tracking number"
              variant="outlined"
              className={classes.input}
              name="tracking"
              onChange={handleChange}
              required
              // multiline
              // rows={2}
              // rowsMax={2}
              style={{ marginTop: 30 }}
              //   disabled
              id="tracking"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },

                // maxLength: 86,
              }}
              // value={address.address}
              //   ref={fileName}
              // value={formData.invoiceName}
            />

            {/* <small style={{ color: "#ccc" }}>
                  Just give it a name so you can remember
                </small> */}

            <TextField
              label="Contents of shipment"
              variant="outlined"
              className={classes.input}
              name="contents"
              onChange={handleChange}
              required
              multiline
              rows={2}
              rowsMax={2}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{ marginTop: 30 }}
              //   disabled
              id="contents"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },

                // maxLength: 86,
              }}
              //   ref={fileName}
              // value={address.addinfo}
            />

            <Divider className={classes.m4} />
            <Typography variant="h6" gutterBottom>
              <CalendarToday />
              &nbsp; Expected date
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                name="edate"
                label="Select date"
                // format="MM/dd/yyyy"
                format="yyyy-MM-dd"
                value={selectedDate}
                onChange={handleDateChange}
                // hintText="Sundays Disabled"
                // shouldDisableDate={disableWeekends}
                // disablePast
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <br />
              <small>
                When will/did the shipment arrive at your U.S address
              </small>
            </MuiPickersUtilsProvider>

            <Grid item xs={12} style={{ marginTop: 30, textAlign: "center" }}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                //   type="submit"
                onClick={handleFormSubmit}
                // type="submit"
              >
                Save and Continue
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
export default PreAlert;
