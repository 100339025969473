import React from "react";
import { myStyles } from "../utils/config";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

import {
  Grid,
  Divider,
  Paper,
  Typography,
  Button,
  Avatar,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
} from "@material-ui/core";
import {
  Store,
  LocationOn,
  Speed,
  Description,
  Delete,
  Launch,
} from "@material-ui/icons";

import { dataProvider, packIcon, scrolly } from "../utils/utility";
// import { mdiConsoleLine } from "@mdi/js";
import Toast from "../components/Toast";
import { settings } from "../utils/config";
import Swal from "sweetalert2";

function ViewPackage() {
  let { id } = useParams();
  const classes = myStyles();
  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  const [data, setData] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const [fileProgress, setFileProgress] = React.useState("hidden");
  //   const [user_info, setUserInfo] = React.useState({});
  const [formData, setFormData] = React.useState({});

  let myFile = React.useRef();
  //   let fileName =  React.useRef();

  React.useEffect(() => {
    loadData();
    // loadInfo();
    loadInvoices();
  }, []);

  const clearFile = () => {
    myFile.current.value = null;
    // fileName.current.value = null;
    setFormData({ ...formData, invoiceFile: null });
    // setFormData({ ...formData, invoiceName: "" });
  };

  const handleFormChange = (e) => {
    //   console.log(formData.inv,"iuinv file")

    if (e.target.files) {
      if (e.target.files[0]?.size > 3000000) {
        setToastMessage("File size should be less than 3MB");
        setIsToastOpen(true);
        myFile.current.value = null;
        setFormData({ ...formData, invoiceFile: null });
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
      return;
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleFormSubmit = () => {
    //   e.preventDefault();
    if (!formData["invoiceName"] || formData["invoiceName"].length < 1) {
      setToastMessage("Invalid invoice name");
      setIsToastOpen(true);
      scrolly("invoiceName");
      return false;
    }

    // const blob = await fetch(formData.invoiceFile.name).then(res => res.blob());

    // console.log(formData.invoiceFile);

    // console.log(e.target.invoiceFile.value, "eeeee")
    // console.log(formData.invoiceFile.value, "eeeee")
    // console.log(formData, "mines")
    const fdata = new FormData();
    fdata.append("invoiceName", formData.invoiceName);
    fdata.append("invoiceFile", formData.invoiceFile);
    fdata.append("tracking", data.tracking);
    fdata.append("order_id", data.id);
    // data.append('my_file', fs.createReadStream(formData.invoiceFile));

    // axios_app.post("/u/upload/invoice/", data, { // receive two parameter endpoint url ,form data
    // })
    // .then(res => { // then print response status
    //     console.warn(res);
    // })
    setFileProgress("visible");
    saveInvoice(fdata);
    // console.log(fData,"fdata")
    // var newFile = fs.createReadStream(formData.invoiceFile.path);
    // fData.append('file', fs.readFileSync(formData.invoiceFile.name));
    // fData.append("file", formData.invoiceFile);

    // console.log(fData.get("invoiceName"));
  };

  async function saveInvoice(d) {
    try {
      const mydata = await dataProvider("post", "/u/upload/invoice/", d);
      //  setUserInfo(mydata.data.data);
      // console.log(mydata.data, "============-info");
      if (mydata.status === 200) {
        setToastMessage(mydata.data.message);
        setIsToastOpen(true);
      }
      setFileProgress("hidden");
      clearFile();
      loadInvoices();
    } catch (error) {
      loadInvoices();
      setToastMessage(error.message);
      setIsToastOpen(true);
      setFileProgress("hidden");
    }
  }

  //   function convertNumber(num, locale) {
  //     const { format } = new Intl.NumberFormat(locale);
  //     const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
  //     return +num
  //       .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
  //       .replace(decimalSign, ".");
  //   }

  //   async function loadInfo() {
  //     try {
  //       const mydata = await dataProvider("get", "/u/info/", []);
  //       setUserInfo(mydata.data.data);
  //       //  console.log(mydata.data.data,"user-info");
  //     } catch (error) {
  //       setToastMessage(error.message);
  //       setIsToastOpen(true);
  //     }
  //   }
  async function loadInvoices() {
    if (!id) return false;
    try {
      const mydata = await dataProvider(
        "get",
        "/u/user-invoice-list/" + id,
        []
      );
      setInvoices(mydata.data.data);
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  async function loadData() {
    if (!id) return false;
    try {
      const mydata = await dataProvider("get", "/u/shipment/" + id, []);
      // console.log(mydata.data.data[0]);
      setData(mydata.data.data[0]);
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  async function deleteInvoice(id, title) {
    Swal.fire({
      // title: 'Are you sure?',
      text: "Delete invoice " + title + "?",
      // icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const mydata = await dataProvider("delete", "/u/user-invoice", {
            id: id,
          });
          if (mydata.status === 200) {
            setToastMessage(mydata.data.message);
            setIsToastOpen(true);
            loadInvoices();
          }
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
        }
      }
    });
  }

  return data?.tracking ? (
    <div>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={11}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom>
              {data?.description}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Order# {data?.id}
            </Typography>

            {/* 
            <Grid container spacing={2} justify="left">

          
                <Grid xs={5}>
Tracking
                </Grid>
                <Grid xs={5}>
                {data?.tracking} 
                </Grid>
                </Grid> */}
            <Divider />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: packIcon(data?.package_state).color,
                      backgroundColor: "white",
                    }}
                  >
                    {packIcon(data?.package_state).icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Status"
                  secondary={
                    <b
                      style={{
                        fontSize: "0.8em",
                        backgroundColor: packIcon(data?.package_state).color,
                        padding: 3,
                        borderRadius: 3,
                      }}
                    >
                      {data?.package_state?.toUpperCase()}
                    </b>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ color: "#514487", background: "white" }}>
                    <LocationOn />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Tracking"
                  secondary={
                    data?.tracking?.length > 18
                      ? data?.tracking?.slice(0, 18) + "..."
                      : data?.tracking
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ color: "#514487", background: "white" }}>
                    <Store />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Shipper" secondary={data?.shipper} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ color: "#514487", background: "white" }}>
                    <Speed />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Weight"
                  secondary={data?.weight + " LB"}
                />
              </ListItem>
            </List>

            <Typography variant="subtitle1" gutterBottom>
              {invoices.length > 0 ? "Saved Invoices" : ""}
            </Typography>
            <Divider />
            {/* <List style={{maxHeight:180, overflowY:"scroll"}}> */}
            <List>
              {invoices.map((i) => {
                return (
                  <ListItem>
                    {/* <ListItemIcon>
                      
                    </ListItemIcon> */}
                    <ListItemAvatar>
                      <Avatar>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={i.title}
                      secondary={new Date(i.edate_z).toLocaleDateString(
                        "en-us",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          // timeZone: 'America/Jamaica',
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    />

                    <Button
                      onClick={() => {
                        deleteInvoice(i.id, i.title);
                      }}
                    >
                      <Delete />
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(settings.uinvoice + i.id);
                      }}
                    >
                      {" "}
                      <Launch />
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={11}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h5" gutterBottom>
              Invoice Upload
            </Typography>

            {/* <form style={{padding:'15px 0px 15px 0px'}}  enctype='multipart/form-data' onSubmit={handleFormSubmit}> */}
            <form className={classes.formRoot} autoComplete="off">
              <Grid item xs={12} md={6}>
                {/* <Paper className={classes.paper}>xs=12</Paper> */}
                <TextField
                  label="Invoice Name"
                  variant="outlined"
                  className={classes.input}
                  name="invoiceName"
                  onChange={handleFormChange}
                  required
                  //   disabled
                  id="registration"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                    maxLength: 26,
                  }}
                  //   ref={fileName}
                  value={formData.invoiceName}
                />
                <br />
                <small style={{ color: "#ccc" }}>
                  Just give it a name so you can remember
                </small>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <input
                  onChange={handleFormChange}
                  type="file"
                  name="invoiceFile"
                  ref={myFile}
                />
                <br />
                <small style={{ color: "#ccc" }}>Upload your invoice</small>
                <LinearProgress style={{ visibility: fileProgress }} />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  //   type="submit"
                  onClick={() => window.history.back()}
                  // type="submit"
                >
                  Go Back
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  //   type="submit"
                  onClick={handleFormSubmit}
                  // type="submit"
                >
                  Save Invoice
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  ) : (
    <div>
      {" "}
      <LinearProgress />
    </div>
  );
}
export default ViewPackage;
