import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuth } from "../utils/utility";
const PrivateRoute = ({
  component: Component,
  subcomponent: SubComponent,
  ...rest
}) => {
 

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => (
        // profile.permission < permission?(<Redirect to="/" />):(<React.Fragment></React.Fragment>)

        <React.Fragment>
          {checkAuth() === true ? (
            <React.Fragment>
              <Component comp={<SubComponent/>} {...props} />
              {/* <Footer /> */}
            </React.Fragment>
          ) : (
            <Redirect to="/signin" />
            // alert(2)
            // alert(2)
            // window.location = settings.signup_url
          )}
        </React.Fragment>
      )}
    />
  );
};

export default PrivateRoute;
