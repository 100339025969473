import React from "react";
import { myStyles } from "../utils/config";
import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { scroller } from "react-scroll";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  //   Divider,
  //   Paper,
  //   Typography,
  Button,
  //   Avatar,
  TextField,
  //   List,
  //   ListItem,
  //   ListItemIcon,
  //   ListItemText,
  //   ListItemAvatar,
  LinearProgress,
  Divider,
  TextareaAutosize,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
// import {
//   Store,
//   LocationOn,
//   FitnessCenter,
//   Speed,
//   PictureAsPdf,
//   Description,
// } from "@material-ui/icons";
// import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../utils/utility";
// import { mdiConsoleLine } from "@mdi/js";
import Toast from "../components/Toast";
// import { settings } from "../utils/config";
import DataTable from "../components/DataTable";
import { CalendarToday, LocalShipping } from "@material-ui/icons";
import moment from "moment";
// var moment = require('moment-timezone');
// moment().tz("America/Jamaica").format();

function RequestDelivery() {
  //   let { id } = useParams();
  let history = useHistory();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  // const [timeRange, setTimeRange] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const classes = myStyles();
  const [progress, setProgress] = React.useState("hidden");

  // console.log(tomorrow)
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     padding: 10,
  //     paddingTop: 60,
  //   },
  // }));
  // const classes = useStyles();
  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  const [tableData, setTableData] = React.useState([{ id: 0 }]);
  const [deliveries, setDeliveries] = React.useState({});
  const [address, setAddress] = React.useState({});
  const handleChange = (event) => {
    // if(event.target.checked === false) return;
    setDeliveries({ ...deliveries, [event.target.name]: event.target.checked });
  };
  const handleAddressChange = (event) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    loadData();
    //get address
    try {
      setAddress(JSON.parse(sessionStorage.getItem("address")));
    } catch (error) { }
  }, []);

  React.useEffect(() => {
    console.log(deliveries);
  }, [deliveries]);

  //   function convertNumber(num, locale) {
  //     const { format } = new Intl.NumberFormat(locale);
  //     const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
  //     return +num
  //       .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
  //       .replace(decimalSign, ".");
  //   }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (date) => {

    // let t = moment(date, "hh:mm");
    // console.log(t)
    // console.log(t.isBefore(endTime));
    //  console.log(t.isAfter(moment('2020-01-01 08:30').format("hh:mm")))
    // if (t.isBetween(b1,e1)) {
    //   console.log('between')
    // }

    setSelectedTime(date.target.value);
    console.log(date.target.value);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -120,
    });
  };
  const handleFormSubmit = () => {
    //   e.preventDefault();

    Object.entries(deliveries).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === false) {
        delete deliveries[v[0]];
      }
    });

    if (Object.keys(deliveries).length < 1) {
      setToastMessage("Select atleast one(1) package");
      setIsToastOpen(true);
      scrolly("deliveries_ele");
      return false;
    }
    let save_deliveries = [];
    Object.entries(deliveries).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === true) {
        save_deliveries.push(v[0]);
      }
    });

    if (!address.address || address.address.length < 5) {
      setToastMessage("Invalid address");
      setIsToastOpen(true);
      // scrolly("invoiceName");
      return false;
    }

    if (!selectedDate) {
      setToastMessage("Invalid delivery date");
      setIsToastOpen(true);
      // scrolly("invoiceName");
      return false;
    }
    if (!selectedTime) {
      setToastMessage("Invalid delivery time");
      setIsToastOpen(true);
      // scrolly("invoiceName");
      return false;
    }

    let today_date = moment().format("YYYY-MM-DD");
    let delivery_date = moment(selectedDate).format("YYYY-MM-DD");
    if (today_date === delivery_date) {
      setToastMessage(
        "Deliveries cannot be requested for today, how about tomorrow?"
      );
      setIsToastOpen(true);
      // scrolly("invoiceName");
      return false;
    }

    // let save_date = delivery_date + " " + moment(selectedTime).format("HH:mm");
    let save_date = delivery_date + " " + selectedTime;

    dataProvider("post", "/u/reqDelivery", {
      save_deliveries,
      address,
      save_date,
    })
      .then((res) => {
        console.log(res);
        setToastMessage(res.data.message);
        setIsToastOpen(true);

        history.push("/dashboard");
      })
      .catch((err) => {
        // console.error(err.message)
        if (err.message) {
          setToastMessage(err.message);
          setIsToastOpen(true);
        } else {
          setToastMessage("Something went wrong, please try again.");
          setIsToastOpen(true);
        }

      });

    // const fdata = new FormData();
    // fdata.append("invoiceName", formData.invoiceName);
  };

  function disableWeekends(date) {
    return date.getDay() === 0;
  }
  async function loadData() {
    try {
      setProgress("visible");
      const mydata = await dataProvider("get", "/u/shipments-ready/50", []);
      // console.log(mydata.data.data,"td");
      setTableData(mydata.data.data);
      // setTableData({});
      setProgress("hidden");
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
      setProgress("hidden");
    }
  }

  const columns = [
    { field: "id", headerName: "Order#", width: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 160,
      editable: false,
    },
    {
      field: "shipper",
      headerName: "Shipper",
      width: 210,
      editable: false,
    },
    {
      field: "tracking",
      headerName: "Tracking#",
      width: 210,
      editable: false,
    },
    {
      field: "total_charge",
      headerName: "Total",
      // type: 'number',
      width: 120,
      // editable: true,
    },
    {
      field: "delivered_date",
      headerName: "Delivered On",
      // type: 'number',
      width: 190,
      // editable: true,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'firstName') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
  ];

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={11}>
          <Typography variant="h6" gutterBottom>
            Request Delivery
          </Typography>
          <Divider />
          {tableData[0]?.tracking ? (
            <Grid item xs={12}>
              {!tableData[0]?.tracking ? (
                <Alert severity="info">
                  No packages available for delivery at the moment 😒
                </Alert>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <div>
              <LinearProgress style={{ visibility: progress }} />
              {/* {console.log(tableData[0]?.tracking)} */}
              {tableData[0]?.tracking === undefined && progress === "hidden" ? (
                <>
                  <Alert severity="info">
                    No packages available for delivery at the moment. 😒
                  </Alert>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      color="default"
                      //   type="submit"
                      onClick={() => window.history.back()}
                    // type="submit"
                    >
                      Go Back
                    </Button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </Grid>

        {tableData[0]?.tracking ? (
          <Grid item xs={12} sm={11} style={{ marginTop: 20 }}>
            {/* <Paper> */}

            {/* </Paper> */}

            <Typography variant="h6" gutterBottom>
              <b>1.</b> Select packages
            </Typography>
            <form className={classes.formRoot} autoComplete="off">
              <input type="hidden" name="deliveries_ele" />
              <FormGroup>
                {tableData?.map((i) => {
                  return (
                    <FormControlLabel
                      style={{ marginBottom: 18 }}
                      control={
                        <Checkbox
                          checked={deliveries[i.id]}
                          onChange={handleChange}
                          name={[i.id]}
                        />
                      }
                      label={
                        <div>
                          <div>
                            {i.description} ({i.shipper})
                          </div>
                          <div
                            style={{
                              fontSize: "0.9em",
                              fontWeight: 600,
                              color: "#2D0C57",
                            }}
                          >
                            {i.total_charge}
                          </div>
                        </div>
                      }
                    />
                  );
                })}
              </FormGroup>
              {/* <FormControlLabel
            control={<Checkbox checked={formState.gilad} onChange={handleChange} name="gilad" />}
            label="Gilad Gray"
          /> */}
              <Divider className={classes.m4} />
              <Typography variant="h6" gutterBottom>
                <LocalShipping />
                &nbsp; Delivery address
              </Typography>

              <TextField
                label="Address"
                variant="outlined"
                className={classes.input}
                name="address"
                onChange={handleAddressChange}
                required
                multiline
                rows={2}
                rowsMax={2}
                style={{ marginTop: 30 }}
                //   disabled
                id="address"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },

                  // maxLength: 86,
                }}
                value={address.address}
              //   ref={fileName}
              // value={formData.invoiceName}
              />

              {/* <small style={{ color: "#ccc" }}>
                  Just give it a name so you can remember
                </small> */}

              <TextField
                label="Delivery instructions"
                variant="outlined"
                className={classes.input}
                name="addinfo"
                onChange={handleAddressChange}
                multiline
                rows={2}
                rowsMax={2}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                style={{ marginTop: 30 }}
                //   disabled
                id="addinfo"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },

                  // maxLength: 86,
                }}
                //   ref={fileName}
                value={address.addinfo}
              />

              <Divider className={classes.m4} />
              <Typography variant="h6" gutterBottom>
                <CalendarToday />
                &nbsp; Delivery date
              </Typography>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // disableToolbar
                  // variant="inline"

                  margin="normal"
                  id="date-picker-dialog"
                  name="delivery_date"
                  label="Select delivery day"
                  // format="MM/dd/yyyy"
                  format="yyyy-MM-dd"
                  value={selectedDate}
                  onChange={handleDateChange}
                  hintText="Sundays Disabled"
                  shouldDisableDate={disableWeekends}
                  disablePast
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <br />
                {/* <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Select time"
                  value={selectedTime}
                  // inputFormat="HH"
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />{" "} */}

             
                <FormControl>
        <InputLabel shrink>
        Delivery time
        </InputLabel>

                <Select
                  value={selectedTime}
                  label="Delivery time"
                  onChange={handleTimeChange}
                  style={{width:220}}
                >
                  <MenuItem value={"9:00"}>Morning 9am - 12pm</MenuItem>
                  <MenuItem value={"13:00"}>Afternoon 1pm - 5pm</MenuItem>

                </Select>
                </FormControl>


              </MuiPickersUtilsProvider>
              {/* <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}

              <Grid item xs={12} style={{ marginTop: 30, textAlign: "center" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  //   type="submit"
                  onClick={handleFormSubmit}
                // type="submit"
                >
                  Save and Continue
                </Button>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid item xs={12}></Grid>
        )}
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
export default RequestDelivery;
