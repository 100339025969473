import React, { useEffect, useState } from "react";
// import { SvgIcon } from "@material-ui/core";
import { useHistory } from "react-router";
import { dataProvider } from "../utils/utility";
import swal from 'sweetalert';
import {
  Grid,
  Typography,
  Avatar,
  Paper,
  Button,
  Divider,
  Chip,
  ListItem,
  ListItemAvatar,
} from "@material-ui/core";
import {
  DoneOutline,
  FlightTakeoff,
  Inbox,
  CloudUpload,
  Description,
  LocalShipping,
} from "@material-ui/icons";
// import { myStyles } from "../utils/config";
import { makeStyles } from "@material-ui/core/styles";
import { settings } from "../utils/config";
import Toast from "../components/Toast";

function Package(props) {
  let history = useHistory();
  const thisStyle = makeStyles(() => ({
    colorReceived: {
      color: "grey",
      // backgroundColor: "grey"
      borderColor: "grey",
      // color:"white"
    },
    colorInTransit: {
      color: "orange",
      borderColor: "orange",
      // backgroundColor: "orange"
      // color:"white"
    },
    colorReady: {
      color: "#0CB6E1",
      // color:"white"
    },
    colorDelivered: {
      color: "green",
      // color:"white"
    },
    colorOnHold: {
      color: "#ff2f4c",
      // color:"white"
    },
    paper: {
      margin: 15,
      textAlign: "left",
      background: "white",
      color: "#2D0C57",
      // width:"100%"
      // color: theme.palette.text.secondary,
    },
    bigLabel: {
      color: "#2D0C57",
      fontSize: "1.3em",
      fontWeight: 600,
      // backgroundColor: "#F4EDF1",
      // padding: 3,
      // borderRadius: 5,
    },
    bigLabel1: {
      color: "#2D0C57",
      fontSize: "1.2em",
      fontWeight: 600,
      backgroundColor: "#F4EDF1",
      padding: 4,
      borderRadius: 5,
    },
    bigLabel2: {
      color: "#98a3b4",
      fontSize: "0.9em",
      fontWeight: 500,
      backgroundColor: "#f9f7f8",
      padding: 3,
      borderRadius: 3,
      // border: "1px solid #bbb",
    },
    primaryButton: {
      fontSize: "0.9em",
      background: "#514487",
      color: "#ffffff",
      textTransform: "none",
      fontWeight: 600,
    },
    greyButton: {
      fontSize: "0.9em",
      background: "#ddd",
      color: "#2D0C57",
      textTransform: "none",
      fontWeight: 600,
    },
  }));
  // const classes = myStyles();
  const css = thisStyle();
  const [packageData, setPackageData] = useState({});
  // const InvetoryIcon = (
  //   <SvgIcon>
  //     <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
  //   </SvgIcon>
  // );
  // const lb = (
  //   <SvgIcon style={{ verticalAlign: "text-bottom", width: 21 }}>
  //     <path
  //       stroke="#9586B0"
  //       fill="none"
  //       d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5Z"
  //     />
  //   </SvgIcon>
  // );

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  const handleDelete = async(id) => {
    // console.info("You clicked the delete icon.");

    swal({
      // title: "Are you sure?",
      text: "Remove delivery request?",
      // icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async(willDelete) => {
      if (willDelete) {
        try {
          // setProgress("visible");
          const mydata = await dataProvider("delete", "/u/reqDelivery", {id:id});
          console.log(mydata.data.data,"td");
          var el = document.getElementById('p_'+id);
          el.remove();
          //hide item
    
          setToastMessage(mydata.data.message);
          setIsToastOpen(true);
         
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
    
        }
      }
    });


  };

  const type = (value) => {
    switch (value) {
      case "received":
        return {
          css: css.colorReceived,
          progress: 33,
          icon: <Inbox className={css.colorReceived} />,
          color: "#efefef",
          // icon: (
          //   <SvgIcon>
          //     <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
          //   </SvgIcon>
          // ),
        };
      case "in-transit":
        return {
          progress: 66,
          css: css.colorInTransit,
          icon: <FlightTakeoff className={css.colorInTransit} />,
          color: "#F8C26F",
        };
      case "ready":
        return {
          progress: 100,
          css: css.colorReady,
          icon: <DoneOutline className={css.colorReady} />,
          color: "#0EBADD",
        };
      case "delivered":
        return {
          progress: 100,
          css: css.colorDelivered,
          icon: <DoneOutline className={css.colorDelivered} />,
          color: "#18CFC4",
        };
      case "onhold":
        return {
          progress: 100,
          css: css.colorOnHold,
          icon: <Inbox className={css.colorOnHold} />,
          color: "#f45368",
        };
      default:
        return { css: css.colorReceived, icon: "" };
    }
  };

  useEffect(() => {
    setPackageData(props.data);
  }, [props.data]);
  return (
    <Grid item xs={12} lg={6}>
      <Paper className={css.paper} style={{ minHeight: 212 }}>
        <div style={{ padding: 6, textAlign: "left" }}>
          <Grid container style={{ paddingTop: 15 }}>
            <Grid item xs={10}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 700, paddingLeft: 10 }}
              >
                {/* {packageData.description} */}
                {/* <Badge badgeContent={packageData.package_state} /> */}

                {packageData?.description?.substring(0, 20)}
              </Typography>
              {/* <div className={type(packageData.package_state).css} style={{paddingLeft: 10, fontWeight:'600'}}>
                {packageData.package_state}
                </div> */}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <span className={css.bigLabel2}>{packageData.id}</span>
            </Grid>
          </Grid>
        </div>

        {/* <Divider variant="middle" /> */}
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              style={{
                color: "#2D0C57",
                position: "absolute",
                background: "none",
              }}
            >
              {type(packageData.package_state).icon}
            </Avatar>

            {/* <CircularProgress
              variant="determinate"
              value={type(packageData.package_state).progress}
              style={{ position: "absolute", color:"#0cb6e1" }}
              // className={type(packageData.package_state).css}
            /> */}
          </ListItemAvatar>
          <div></div>
          <Grid container style={{ paddingLeft: 10 }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">{packageData.shipper}</Typography>
              <div>
                <small style={{ color: "#d1c8cd" }}>
                  {packageData.tracking}
                </small>
              </div>
            </Grid>
            <Grid item xs={12}>
              {packageData?.delivery_date ? (
                <Chip
                variant="outlined"
                  icon={<LocalShipping />}
                  label={packageData.delivery_date}
                  onDelete={()=> handleDelete(packageData.id)}
                  color="secondary"
                  id={"p_"+packageData.id}
                  //  style={{margin:"10px 0px 10px 0px"}}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12}>
              {/* <span style={{ color:"#2D0C57", fontSize:"1.8em", fontWeight:600}}> */}
              {/* <span
              style={{
                backgroundColor: type(packageData.package_state).color,
                color: "#424242",
                padding: 6,
                borderRadius: 4,
                fontWeight: "700",
                fontSize:'8pt',
                textAlign:'center'
              }}
            >
              {packageData.package_state &&
                packageData.package_state.toUpperCase()}
            </span> &nbsp; &nbsp; */}
              {packageData && packageData.package_state === "ready" ? (
                <span className={css.bigLabel1}>
                  {packageData.total_charge}
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: type(packageData.package_state).color,
                    color: "#424242",
                    padding: 5,
                    borderRadius: 4,
                    fontWeight: "700",
                    fontSize: "0.8em",
                    textAlign: "center",
                  }}
                >
                  {packageData.package_state &&
                    packageData.package_state.toUpperCase()}
                </span>
              )}
              &nbsp;&nbsp;
              {/* <Typography variant="body" className={css.bigLabel}>
                {packageData.weight}
              </Typography> */}
              {/* &nbsp;
              <small style={{ fontSize: "0.7em", color: "#9586A8" }}>LB</small> */}
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ListItem>
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12}>
            <Divider style={{ marginBottom: 6 }} />
          </Grid>

          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography variant="caption" className={css.bigLabel}>
              {packageData.weight}
            </Typography>
            &nbsp;
            <small style={{ fontSize: "0.7em", color: "#9586A8" }}>LB</small>
          </Grid>

          <Grid item xs={8} style={{ textAlign: "right" }}>
            {/* <Button color="primary">UPLOAD INVOICE</Button> */}
            {packageData && packageData.package_state === "ready" ? (
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={css.primaryButton}
                startIcon={<Description />}
                onClick={() => {
                  window.open(settings.invoice + packageData.id);
                }}
              >
                View Invoice
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                className={css.greyButton}
                onClick={() => {
                  history.push("/package/" + packageData.id);
                }}
                startIcon={<CloudUpload />}
              >
                Upload Invoice
              </Button>
            )}
            {/* <Button color="primary">MORE</Button> */}
          </Grid>
        </Grid>
      </Paper>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </Grid>
  );
}

export default Package;
