import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from "@material-ui/icons/Menu";
// import Menu from '@material-ui/core/Menu';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { myStyles, settings } from "../utils/config";
import { useHistory } from "react-router-dom";
import logo from "../assets/shippcart_logo.png";
import {
  Dashboard,
  Settings,
  ExitToApp,
  SupervisorAccount,
  History,
  LocalShipping,
  AddAlert,
  ListAlt,
} from "@material-ui/icons";

import { dataProvider, goTo } from "../utils/utility";
function Nav(props) {
  let history = useHistory();
  const { window } = props;
  const classes = myStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user_info, setUserInfo] = React.useState({});
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clickLink = (e, text) => {
    if (text[1] === "/settings") {
      goTo(settings.settings_url);
    } else {
      history.push("" + text[1]);
    }

    setMobileOpen(false);
  };
  React.useEffect(() => {
    loadInfo();
  }, []);

  async function loadInfo() {
    try {
      const mydata = await dataProvider("get", "/u/info", []);
      setUserInfo(mydata.data.data);
      sessionStorage.setItem('address', JSON.stringify({address: mydata.data.data.address, addinfo: mydata.data.data.addinfo}));
      //  console.log(mydata.data.data,"user-info");
    } catch (error) {
      // setToastMessage(error.message);
      // setIsToastOpen(true);
    }
  }

  // console.log(props.comp)
  const drawer = (
    <div>
      <div
        style={{ textAlign: "center", width: "auto", padding: "15px 0 10px 0" }}
      >
        <a href={settings.website}>
          <img alt="logo" style={{ width: 150 }} src={logo} />
        </a>
        {/* <div style={{fontSize:'1.3em'}}>{settings.name}</div> */}
      </div>
      <div className={classes.toolbar} style={{ marginTop: 0 }} />
      <Divider />
      <div style={{ padding: "15px 6px 15px 18px", fontSize: "1.1em" }}>
        <div>
          {`Account#: `} <b style={{ color: "red" }}>{user_info?.account_id}</b>
        </div>
        <div>
          {`Delivery PIN: `} <b style={{ color: "#666" }}>{user_info?.pin}</b>
        </div>
      </div>

      <Divider />

      <List>
        {[
          ["Dashboard", "/dashboard", <Dashboard />],
          ["Shipment History", "/history", <History />],
          ["Request Delivery", "/request-delivery", <LocalShipping />],
          ["Make Pre-Alert", "/pre-alert", <AddAlert />],
          ["View Pre-Alerts", "/pre-alerts", <ListAlt />],
          ["Account Settings", "/settings", <Settings />],
          ["Sign out", "/signout", <ExitToApp />],
        ].map((menu, index) => (
          // {[['Dasboard',"/dashboard"],['Packages',"/packages"],['Schedule Delivery',"/activity"],['Messages',"/messages"],].map((text, index) => (
          <ListItem button key={menu[0]} onClick={(e) => clickLink(e, menu)}>
            <ListItemIcon>{menu[2]}</ListItemIcon>
            <ListItemText primary={menu[0]} />
          </ListItem>
        ))}

        {user_info?.permission > 0 ? (
          <div>
            <Divider />
            <ListItem button key={"CRM"} onClick={() => history.push("/crm")}>
              <ListItemIcon>{<SupervisorAccount />}</ListItemIcon>
              <ListItemText primary={"CRM"} />
            </ListItem>
          </div>
        ) : (
          ""
        )}
      </List>
      <Divider />
      <div
        style={{ textAlign: "center", color: "#636363", paddingTop: "20px" }}
      >
        <br />
        <b>{settings.name}</b>
        <br />
        <a
          style={{
            fontSize: "0.9em",
            color: "#0ebadd",
            textDecoration: "none",
          }}
          target="_shipp"
          href={"mailto:" + settings.email}
        >
          {settings.email}
        </a>
        <br />
        <a
          style={{
            fontSize: "0.9em",
            color: "#0ebadd",
            textDecoration: "none",
          }}
          target="_shipp"
          href={"tel:" + settings.phone}
        >
          {settings.phone}
        </a>
      </div>

      {/* <List>
      {[['Activity Log',"/dashboard"],['Account',"/account"],['Deliveries',"/activity"],['Messages',"/messages"],].map((text, index) => (
          <ListItem button key={text[0]} onClick={(e)=>clickLink(e, text)}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text[0]} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {user_info?.fname ? "👋Hi " + user_info.fname : ""}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.comp}
      </main>
    </div>
  );
}

Nav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Nav;
