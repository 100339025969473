import React from 'react';
import { Link } from 'react-router-dom';
import img from '../assets/404.png'
const NotFound = () => (
  <div style={{textAlign:"center", color:"white", background:"#514487", height:"100vh", maxHeight: '100vh', margin:0}}>
      <img src={img} style={{width:"40%",maxWidth:400, marginTop:'20vh'}}/>
    <h2>Oops, This Page is Burnt to a Crisp!</h2>
    <Link to="/" style={{textDecoration:"none", color:"white"}}>
      Go Home
    </Link>
  </div>
);

export default NotFound;