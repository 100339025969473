import React from "react";
import Alert from "@material-ui/lab/Alert";
import {
  Grid,
  Typography,
  Button,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import { dataProvider } from "../utils/utility";
import Toast from "../components/Toast";
import ListDataTable from "../components/ListDataTable";
import Swal from 'sweetalert2';

function ViewPreAlerts() {
  const [progress, setProgress] = React.useState("hidden");

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  const [tableData, setTableData] = React.useState([{ id: 0 }]);

  React.useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      setProgress("visible");
      const mydata = await dataProvider("get", "/u/preAlerts/50", []);
      // console.log(mydata.data.data[0],"td");
      setTableData(mydata.data.data);
      setProgress("hidden");
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
      setProgress("hidden");
    }
  }

  async function deletePreAlert(id) {


    Swal.fire({
      // title: 'Are you sure?',
      text: "Delete pre-alert "+id+"?",
      // icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try {
          const mydata = await dataProvider("delete", "/u/preAlert", {id:id});
          if (mydata.status === 200) {
            setToastMessage(mydata.data.message);
            setIsToastOpen(true);
            loadData();
          }
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
        }
      }
    })

  
  }

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "merchant",
      headerName: "Merchant",
      width: 160,
      editable: false,
    },
    {
      field: "tracking",
      headerName: "Tracking#",
      width: 210,
      editable: false,
    },
    {
      field: "contents",
      headerName: "Contents",
      width: 210,
      editable: false,
    },
    {
      field: "edate",
      headerName: "Expected date",
      // type: 'number',
      width: 190,
      // editable: true,
    },

  ];

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={11}>
          <Typography variant="h6" gutterBottom>
            My Pre-Alerts
          </Typography>
          <Divider />
          {tableData[0]?.tracking ? (
            <Grid item xs={12}>
              {!tableData[0]?.tracking ? (
                <Alert severity="info">No pre-alerts available.</Alert>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <div>
              <LinearProgress style={{ visibility: progress }} />
              {/* {console.log(tableData[0]?.tracking)} */}
              {tableData[0]?.tracking === undefined && progress === "hidden" ? (
                <>
                  <Alert severity="info">No pre-alerts available.</Alert>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      color="default"
                      //   type="submit"
                      onClick={() => window.history.back()}
                      // type="submit"
                    >
                      Go Back
                    </Button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={11}>
          {/* <Paper> */}
          {tableData[0]?.tracking ? (
            <ListDataTable data={tableData} callbackFunc={deletePreAlert} columns={columns} />
          ) : (
            ""
          )}
          {/* </Paper> */}
        </Grid>
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
export default ViewPreAlerts;
